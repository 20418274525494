import { createStore, createLogger } from 'vuex';
import example from './modules/example';

const store = new createStore({
  modules: { example },
  strict: process.env.VUE_APP_ENV === 'development',
  plugins: process.env.VUE_APP_ENV === 'development' ? [createLogger()] : []
});

export default store;
