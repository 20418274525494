import { createApp } from 'vue';
import App from './App.vue';

import store from './store';

import { makeServer } from './mocks';

if (process.env.VUE_APP_ENV === 'development' || process.env.VUE_APP_ENV === 'testing') {
  makeServer();
}

const app = createApp(App);
app.use(store);
app.mount('#app');
window.app = app;
