import { set } from 'vue';
import axios from 'axios';
import qs from 'qs';
import {
  getAndCommit,
  getAndCommitList,
  createAndCommit,
  updateAndCommit,
  deleteAndCommit,
  removeById
} from '../data-utils';

const paramsSerializer = params => qs.stringify(params, { encode: false, arrayFormat: 'repeat' });
const apiurl = process.env.VUE_APP_API_URL;

const emptyState = () => {
  return {
    title: ''
  };
};

const state = () => emptyState;

const getters = {
  getTitle: state => state.title
};

const actions = {
  reset ({ commit }) {
    commit('reset');
  },

  get (_, { path, params }) {
    return axios.get(apiurl + path, {
      params,
      paramsSerializer
    });
  },
  post (_, { path, formData }) {
    return axios.post(path, formData);
  },
  delete (_, { path, params }) {
    return axios.delete(path, {
      params,
      paramsSerializer
    });
  },

  fetchTitle: getAndCommit('/example', 'title')
};

const mutations = {
  reset (state) {
    state = emptyState();
  },
  title (state, data) {
    state.title = data.title;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
