export const removeById = (rs, id) => {
  const idx = rs.findIndex(f => f.id === id);
  if (idx === -1) {
    return false;
  }
  const rsCopy = [...rs];
  rsCopy.splice(idx, 1);
  return rsCopy;
};

const evalPathThunk = (pathOrThunk, payload) => {
  if (typeof pathOrThunk === 'string') {
    return pathOrThunk + (payload.id ? '/' + payload.id : '');
  } else {
    return pathOrThunk(payload);
  }
};

export const getAndCommit = (pathOrThunk, mutation) => {
  return async ({ state, dispatch, commit }, id) => {
    console.log('getAndCommit', { id, state });
    const path = evalPathThunk(pathOrThunk, { id, state });
    const { data } = await dispatch('get', {
      path
    });
    commit(mutation, data);
  };
};

export const getAndCommitList = (pathOrThunk, mutationList, mutationTotal) => {
  return async ({ state, dispatch, commit }, params) => {
    const path = evalPathThunk(pathOrThunk, { params, state });
    const { data } = await dispatch('get', {
      path,
      params
    });
    commit(mutationList, data.items);
    if (mutationTotal) {
      commit(mutationTotal, data.count);
    }
  };
};

export const createAndCommit = (pathOrThunk, mutation) => {
  return async ({ state, dispatch, commit }, formData) => {
    const path = evalPathThunk(pathOrThunk, { formData, state });
    const { data } = await dispatch('post', {
      path,
      formData
    });
    commit(mutation, data);
  };
};

export const updateAndCommit = (pathOrThunk, mutation) => {
  return async ({ state, dispatch, commit }, { id, formData }) => {
    console.log('updateAndCommit', { id, formData });
    const path = evalPathThunk(pathOrThunk, { id, formData, state });
    const { data } = await dispatch('post', {
      path,
      formData
    });
    commit(mutation, data);
  };
};

export const deleteAndCommit = (pathOrThunk, mutation) => {
  return async ({ state, dispatch, commit }, id) => {
    const path = evalPathThunk(pathOrThunk, { id, state });
    await dispatch('delete', {
      path
    });
    commit(mutation, id);
  };
};